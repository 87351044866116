// components/FridgeManagement.js

import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  query,
  where,
} from 'firebase/firestore';
import { useUser } from '../contexts/UserContext';
import { ClockIcon, TrashIcon } from '@heroicons/react/24/outline';
import ConfirmationModal from './ConfirmationModal';
import AddFridgeModal from './AddFridgeModal';
import TemperatureHistoryModal from './TemperatureHistoryModal';
import Modal from './Modal'; // Assurez-vous que ce composant est correctement implémenté

function FridgeCard({ fridge, onLocalUpdate, selectedDate, onDelete, onShowHistory }) {
  const [name, setName] = useState(fridge.fridgeName);
  const [temperature, setTemperature] = useState(fridge.temperatures[selectedDate]?.toString() || '');
  const [isEditingName, setIsEditingName] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNameBlur = async () => {
    setIsEditingName(false);
    if (name !== fridge.fridgeName) {
      const fridgeDocRef = doc(db, 'tempFrigo', fridge.id);
      await updateDoc(fridgeDocRef, { fridgeName: name });
      // Mettre à jour le state local
      onLocalUpdate(fridge.id, { fridgeName: name });
    }
  };

  const handleTemperatureChange = (e) => {
    setTemperature(e.target.value);
  };

  const handleTemperatureBlur = async () => {
    const newTemperature = Number(temperature);
    if (!isNaN(newTemperature) && newTemperature !== (fridge.temperatures[selectedDate] || 0)) {
      const fridgeDocRef = doc(db, 'tempFrigo', fridge.id);
      await updateDoc(fridgeDocRef, {
        [`temperatures.${selectedDate}`]: newTemperature,
      });
      // Mettre à jour le state local
      onLocalUpdate(fridge.id, {
        temperatures: {
          ...fridge.temperatures,
          [selectedDate]: newTemperature,
        },
      });
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-200">
      <div className="px-6 py-4">
        <div className="flex items-center justify-between">
          {isEditingName ? (
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              className="text-lg font-semibold text-gray-800 focus:outline-none border-b-2 border-gray-300 focus:border-[#003366]"
              autoFocus
            />
          ) : (
            <h3
              className="text-lg font-semibold text-gray-800 cursor-pointer"
              onClick={() => setIsEditingName(true)}
            >
              {name}
            </h3>
          )}
          <div className="flex space-x-2">
            <button onClick={() => onShowHistory(fridge)} title="Historique des températures">
              <ClockIcon className="h-5 w-5 text-gray-500 hover:text-[#003366]" />
            </button>
            <button onClick={() => onDelete(fridge.id)} title="Supprimer le frigo">
              <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-700" />
            </button>
          </div>
        </div>
        <div className="mt-4">
          <label className="block text-sm text-gray-600">Température du {selectedDate} :</label>
          <input
            type="number"
            value={temperature}
            onChange={handleTemperatureChange}
            onBlur={handleTemperatureBlur}
            className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-[#003366] focus:ring-[#003366]"
            placeholder="Entrez la température"
          />
        </div>
      </div>
    </div>
  );
}

function FridgeManagement({ selectedEnterpriseId }) { // Accepter selectedEnterpriseId en tant que prop
  const [fridges, setFridges] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const { enterpriseId, organizationId } = useUser();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [fridgeToDelete, setFridgeToDelete] = useState(null);
  const [addFridgeModalOpen, setAddFridgeModalOpen] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [selectedFridgeForHistory, setSelectedFridgeForHistory] = useState(null);

  const requestDelete = (fridgeId) => {
    setFridgeToDelete(fridgeId);
    setConfirmModalOpen(true);
  };

  const deleteFridge = async () => {
    if (!fridgeToDelete) return;
    await deleteDoc(doc(db, "tempFrigo", fridgeToDelete));
    // Mettre à jour le state local
    setFridges(prevFridges => prevFridges.filter(fridge => fridge.id !== fridgeToDelete));
    setConfirmModalOpen(false);
    setFridgeToDelete(null);
  };

  const addFridge = async (fridgeName) => {
    const newFridgeData = {
      fridgeName: fridgeName,
      temperatures: {},
      enterpriseId: selectedEnterpriseId, // Associer le frigo à l'établissement sélectionné
    };
    const docRef = await addDoc(collection(db, "tempFrigo"), newFridgeData);
    // Ajouter le nouveau frigo au state local
    setFridges(prevFridges => [
      ...prevFridges,
      { ...newFridgeData, id: docRef.id }
    ]);
    setAddFridgeModalOpen(false);
  };

  const handleShowHistory = (fridge) => {
    setSelectedFridgeForHistory(fridge);
    setShowHistoryModal(true);
  };

  const fetchFridges = async () => {
    if (!selectedEnterpriseId) return;

    const fridgesCollectionRef = collection(db, "tempFrigo");
    const q = query(fridgesCollectionRef, where("enterpriseId", "==", selectedEnterpriseId));
    const querySnapshot = await getDocs(q);
    const filteredFridges = querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      temperatures: doc.data().temperatures || {}
    }));
    setFridges(filteredFridges);
  };

  const onLocalUpdate = (fridgeId, updatedData) => {
    setFridges(prevFridges =>
      prevFridges.map(fridge =>
        fridge.id === fridgeId ? { ...fridge, ...updatedData } : fridge
      )
    );
  };

  useEffect(() => {
    fetchFridges();
  }, [selectedEnterpriseId]); // Mettre à jour lorsque selectedEnterpriseId change

  const generateCSVData = () => {
    const csvData = [];
    fridges.forEach(fridge => {
      Object.entries(fridge.temperatures || {}).forEach(([date, temp]) => {
        if (date >= startDate && date <= endDate) {
          csvData.push({
            FridgeName: fridge.fridgeName,
            Date: date,
            Temperature: temp
          });
        }
      });
    });
    return csvData;
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Gestion des températures</h1>
      <div className="flex justify-between items-center mb-4">
        <button onClick={() => setAddFridgeModalOpen(true)} className="bg-[#003366] hover:bg-[#3399FF] text-white font-bold py-2 px-4 rounded-full">
          Ajouter un frigo
        </button>
        <AddFridgeModal
          isOpen={addFridgeModalOpen}
          onClose={() => setAddFridgeModalOpen(false)}
          onAdd={addFridge}
        />
        <button className="bg-[#003366] hover:bg-[#3399FF] text-white font-bold py-2 px-4 rounded-full" onClick={() => setModalOpen(true)}>
          Extraire l'historique
        </button>
      </div>

      {modalOpen && (
        <Modal
          onClose={() => setModalOpen(false)}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          csvData={generateCSVData()}
          filename={`fridge-temperatures-${startDate}-to-${endDate}.csv`}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-left">
        {fridges.length === 0 ? (
          <div className="col-span-1 md:col-span-3 text-center text-gray-500">
            Vous trouverez ici les informations concernant les températures des frigos enregistrés.
          </div>
        ) : (
          fridges.map(fridge => (
            <FridgeCard
              key={fridge.id}
              fridge={fridge}
              onLocalUpdate={onLocalUpdate}
              selectedDate={startDate}
              onDelete={requestDelete}
              onShowHistory={handleShowHistory}
            />
          ))
        )}
        {showHistoryModal && (
          <TemperatureHistoryModal
            isOpen={showHistoryModal}
            onClose={() => setShowHistoryModal(false)}
            fridge={selectedFridgeForHistory}
          />
        )}
        <ConfirmationModal
          isOpen={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={deleteFridge}
        />
      </div>
    </div>
  );
}

export default FridgeManagement;
