import React from 'react';

const FeatureSection = ({ title, description, imageSrc, imageAlt, reverse = false }) => (
  <div
    className={`flex flex-col ${
      reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'
    } items-center gap-8 lg:gap-[38px] py-8 lg:py-12`}
  >
    {/* Texte */}
    <div className="w-full lg:w-1/2 text-left">
      <h2 className="text-[#212121] text-2xl sm:text-3xl lg:text-4xl font-bold mb-4">
        {title}
      </h2>
      <p className="text-[#666666] text-base sm:text-lg lg:text-xl font-normal leading-relaxed lg:leading-[30px]">
        {description}
      </p>
    </div>

    {/* Image avec cercle de fond */}
    <div className="w-full lg:w-1/2 flex justify-center items-center">
      <div className="relative w-[250px] h-[250px] sm:w-[300px] sm:h-[300px] lg:w-[387px] lg:h-[387px] flex justify-center items-center">
        <img
          src={imageSrc}
          alt={imageAlt}
          className="w-full h-full object-contain rounded-full"
        />
      </div>
    </div>
  </div>
);

export function Features() {
  return (
    <div className="min-h-screen px-4 sm:px-6 lg:px-20 py-12 bg-white flex flex-col justify-start items-center gap-8 lg:gap-16">
      {/* Première Section */}
      <FeatureSection
        title="Concentrez-vous sur l'essentiel."
        description="Simplifiez votre gestion quotidienne avec un outil qui centralise les contrôles d’hygiène. Suivi des températures, dates de péremption, traçabilité des produits : tout est sous contrôle, vous permettant de vous concentrer sur votre cœur de métier."
        imageSrc="/Preparingdish.svg"
        imageAlt="Concentrez-vous sur l'essentiel"
        reverse={false}
      />

      {/* Deuxième Section */}
      <FeatureSection
        title="Une gestion au petits oignons."
        description="Maîtrisez votre stock avec précision. Assurez la conformité de vos pratiques en suivant les règles d'hygiène, limitez le gaspillage, et recevez des notifications pour anticiper les actions nécessaires."
        imageSrc="/Kitchen.svg"
        imageAlt="Une gestion au petits oignons"
        reverse={true}
      />
    </div>
  );
}