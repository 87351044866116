import { db, auth, functions } from '../firebase'; // Assurez-vous d'importer functions ici
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc, 
  deleteDoc, 
  doc, 
  getDoc 
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

export const getUsersByEnterpriseId = async (enterpriseId) => {
  const users = [];
  const q = query(collection(db, 'users'), where('enterpriseID', '==', enterpriseId));
  const snapshot = await getDocs(q);
  snapshot.forEach(doc => {
    users.push({ id: doc.id, ...doc.data() });
  });
  return users;
};

// Fonction callable pour créer l'utilisateur en backend
export const createUserInBackend = async (email, enterpriseId, role) => {
  if (auth.currentUser) {
    //const createUser = httpsCallable(functions, 'createUserWithUid');
    const createUser = httpsCallable(functions, 'createUserWithUidProd');
    try {
      const result = await createUser({ email, enterpriseId, role });
      if (result.data.success) {
        
      } else {
        
      }
    } catch (error) {
      alert('Erreur lors de la création de l\'utilisateur:', error);
    }
  } else {
    alert('Vous devez être connecté pour effectuer cette action');
  }
};

export const addUserToEnterprise = async (email, enterpriseId, role) => {
  // Appel à la fonction callable pour gérer la création de l'utilisateur dans Firebase Functions
  await createUserInBackend(email, enterpriseId, role);
};

export const updateUserRole = async (userId, email, role) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, { email, role });
    
  } catch (error) {
    console.error('Erreur dans updateUserRole:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await deleteDoc(userRef);
  } catch (error) {
    console.error('Erreur dans deleteUser:', error);
    throw error;
  }
};

export const getAllUsersByOrganizationId = async (organizationId) => {
  const users = [];
  const q = query(collection(db, 'users'), where('organizationID', '==', organizationId));
  const snapshot = await getDocs(q);
  snapshot.forEach(doc => {
    users.push({ id: doc.id, ...doc.data() });
  });
  return users;
};

export const updateUserEnterprise = async (userId, enterpriseId) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    enterpriseID: enterpriseId,
  });
};
