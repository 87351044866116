import React from 'react';

const FeatureCard = ({ title, description, svgName }) => (
  <div className="bg-white rounded-lg border border-[#e0e0e0] flex flex-col">
    <div className="h-48 w-full relative bg-[#dfedf8] border-b border-[#e0dfdd] flex items-center justify-center">
      {svgName && (
        <img 
          src={`/${svgName}`} 
          alt={title} 
          className="w-full h-full object-contain p-4"
        />
      )}
    </div>
    <div className="px-6 py-4 flex flex-col justify-start items-start gap-2">
      <h3 className="text-[#212121] text-xl font-semibold text-left leading-8">{title}</h3>
      <p className="text-[#8e8e8e] text-base font-normal text-left leading-normal">{description}</p>
    </div>
  </div>
);

export function SecondaryFeatures() {
  const features = [
    {
      title: "Contrôler les dates limites de consommation",
      description: "Surveillez et gérez facilement les dates de péremption pour assurer la fraîcheur de vos produits.",
      svgName: "dlc.svg",
    },
    {
      title: "Contrôle à la réception des marchandises",
      description: "Simplifiez le processus de vérification des livraisons pour garantir la qualité des produits reçus.",
      svgName: "reception.svg",
    },
    {
      title: "Soyez toujours informé",
      description: "Recevez des alertes personnalisées pour rester au courant des tâches importantes et des échéances.",
      svgName: "informe.svg",
    },
    {
      title: "Une vue détaillée des actions à mener",
      description: "Suivez en temps réel les tâches à accomplir et celles déjà réalisées pour une gestion efficace.",
      svgName: "details.svg",
    },
    {
      title: "Un agent intelligent pour vous accompagner",
      description: "Bénéficiez d'une assistance IA basée sur les normes HACCP pour une gestion optimale de la sécurité alimentaire.",
      svgName: "agent.svg",
    },
    {
      title: "Un historique facile à retrouver",
      description: "Accédez rapidement à vos données archivées, conformément aux exigences légales de conservation.",
      svgName: "historique.svg",
    }
  ];

  return (
    <div className="min-h-screen px-4 sm:px-6 lg:px-20 pt-12 lg:pt-12 pb-7 bg-white flex flex-col justify-center items-center gap-12">
      <div className="flex flex-col items-start gap-2 w-full max-w-6xl">
        <h2 className="text-[#212121] text-3xl sm:text-4xl font-bold text-left">Solution tout en un</h2>
        <p className="text-[#666666] text-lg sm:text-xl font-normal text-left">
          Simplifiez la gestion de vos obligations sanitaires avec notre solution intuitive.
        </p>
      </div>
      <div className="w-full max-w-6xl px-4 sm:px-6 lg:px-0">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <FeatureCard 
              key={index} 
              title={feature.title} 
              description={feature.description} 
              svgName={feature.svgName} 
            />
          ))}
        </div>
      </div>
    </div>
  );
}
