import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const faqs = [
  {
    id: 1,
    question: "Quels types de paiements acceptez-vous ?",
    answer: "Nous acceptons les paiements par carte de crédit sur le site. \n Pour un réglement par virement bancaire ou tout autre methode contactez nous directement.",
  },
  {
    id: 2,
    question: "Comment puis-je annuler mon abonnement ?",
    answer: "Vous pouvez annuler votre abonnement à tout moment depuis votre espace client. Si vous rencontrez des difficultés, notre service client est là pour vous aider.",
  },
  {
    id: 3,
    question: "Offrez-vous une période d'essai gratuite ?",
    answer: "Oui, nous offrons une période d'essai de 15 jours pendant laquelle vous pouvez tester toutes les fonctionnalités de notre application sans engagement.",
  },
  {
    id: 4,
    question: "Puis-je changer de plan à tout moment ?",
    answer: "Absolument, vous pouvez changer de plan à tout moment en fonction de vos besoins. Les modifications seront appliquées immédiatement.",
  },
  {
    id: 5,
    question: "Comment sécurisez-vous les données de vos utilisateurs ?",
    answer: "La sécurité de vos données est notre priorité. Nous utilisons des protocoles de chiffrement et des mesures de sécurité rigoureuses pour protéger vos informations.",
  },
];

export function Faq() {
  return (
    <div className="h-auto px-4 sm:px-6 lg:px-20 pt-[120px] pb-[60px] bg-white flex flex-col lg:flex-row justify-start items-start gap-6">
      <div className="w-full lg:w-[315px] flex-col justify-start items-start gap-4 inline-flex mb-8 lg:mb-0">
        <h2 className="text-[#212121] text-3xl sm:text-4xl font-bold ]">Nos réponses aux questions les plus courantes</h2>
        <p className="text-[#666666] text-lg sm:text-xl font-normal ] leading-[30px]">N'hesitez pas à nous contacter pour avoir plus d'informations </p>
      </div>
      <div className="grow shrink basis-0 flex-col justify-center items-center gap-4 inline-flex">
        {faqs.map((faq) => (
          <Disclosure key={faq.id} as="div" className="w-full">
            {({ open }) => (
              <div className="w-full p-6 bg-[#e3f1ff] rounded-lg">
                <Disclosure.Button className="w-full flex justify-between items-center text-left">
                  <span className="text-[#172026] text-lg font-medium ] leading-normal">{faq.question}</span>
                  <ChevronDownIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-6 h-6 text-[#172026]`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-4">
                  <p className="text-[#35485c] text-lg text-left font-normal ] leading-7 whitespace-pre-line">{faq.answer}</p>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}