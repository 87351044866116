import { useState, Fragment } from 'react';
import { RadioGroup, Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useUser } from '../contexts/UserContext'; // Assurez-vous que le chemin est correct

//const stripePromise = loadStripe('pk_test_51ORksFBHy4enxmepqO7Hy89SsLaktx6jnvvcMPEzbe6T2tS8T3Gh8Aay1DPFY95lkN2qZ6IH4sSH4MLCPRj1I6J900cuMSEgic');
const stripePromise = loadStripe('pk_live_51ORksFBHy4enxmepCntWrkTGTOJSVjDvBhk8q8BTWmRjq5uljFuWl7edU1jn9GMJ5gmRI46bxElzyKIKfOYiiqdC00WTofzkkn');
const frequencies = [
  { value: 'monthly', label: 'Mensuel', priceSuffix: '/mois' },
  { value: 'annually', label: 'Annuel', priceSuffix: '/an' },
];

const tiers = [
  {
    name: 'Café',
    id: 'tier-cafe',
    price: { monthly: '29€ HT', annually: '305€ HT' },
    priceId: { monthly: 'price_1Q2kDyBHy4enxmepUayD3rFp', annually: 'price_1Q2kDyBHy4enxmepIPnXy6e2' },
    description: 'Notre plus petite offre, dimensionnée pour les petits restaurants. Souhaitant gérer leur activité simplement.',
    features: ['8 utilisateurs, 2 enseignes', 'Support en 24h', 'Gestion de commandes de base', 'Analyses de base'],
    featured: false,
    cta: 'Choisir ce plan',
  },
  {
    name: 'Bistro',
    id: 'tier-bistro',
    price: { monthly: '49€ HT', annually: '515€ HT' },
    priceId: { monthly: 'price_1Q2kDtBHy4enxmep2qQ3Sbwm', annually: 'price_1Q2kDtBHy4enxmepYYYDlj2N' },
    description: "Idéal pour les restaurateurs avec un grand nombre d'employés ou les chaines de restaurants.",
    features: ['50 utilisateurs, 5 enseignes', 'Support en moins de 4h', 'Gestion avancée des commandes', 'Analyses avancées', 'Automatisations marketing'],
    featured: false,
    cta: 'Choisir ce plan',
  },
];

const tierstest = [
  {
    name: 'Café',
    id: 'tier-cafe',
    price: { monthly: '29€ HT', annually: '305€ HT' },
    priceId: { monthly: 'price_1P7mzUBHy4enxmepZte6w1uk', annually: 'price_1PxeYXBHy4enxmepGhRoh9h7' },
    description: 'Notre plus petite offre, dimensionnée pour les petits restaurants. Souhaitant gérer leur activité simplement.',
    features: ['8 utilisateurs, 2 enseignes', 'Support en 24h', 'Gestion de commandes de base', 'Analyses de base'],
    featured: false,
    cta: 'Choisir ce plan',
  },
  {
    name: 'Bistro',
    id: 'tier-bistro',
    price: { monthly: '49€ HT', annually: '525€ HT' },
    priceId: { monthly: 'price_1P7n19BHy4enxmepunYgnngU', annually: 'price_1P7n2EBHy4enxmepnE1Vw3l9' },
    description: "Idéal pour les restaurateurs avec un grand nombre d'employés ou les chaines de restaurants.",
    features: ['50 utilisateurs, 5 enseignes', 'Support en moins de 4h', 'Gestion avancée des commandes', 'Analyses avancées', 'Automatisations marketing'],
    featured: false,
    cta: 'Choisir ce plan',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function Subscribe() {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const [isLoading, setIsLoading] = useState(false); // État de chargement
  const [isModalOpen, setIsModalOpen] = useState(false); // État de la modal
  const functions = getFunctions(undefined, 'europe-west1');
  //const createSubscriptionSession = httpsCallable(functions, 'createSubscriptionSession');
  const createSubscriptionSession = httpsCallable(functions, 'createSubscriptionSessionProd');
  const { enterpriseId, loading } = useUser();

  const handleSubscription = async (priceId) => {
    if (!enterpriseId) {
      alert("Enterprise ID is missing. Please contact support.");
      return;
    }
    
    setIsLoading(true); // Activer le chargement
    setIsModalOpen(true); // Ouvrir la modal
    const stripe = await stripePromise;

    try {
      const { data } = await createSubscriptionSession({
        enterpriseId,
        priceId,
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/canceled`,
      });
      const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });
      if (result.error) {
        alert(`Erreur lors du paiement: ${result.error.message}`);
      }
    } catch (error) {
      alert(`Erreur lors de la création de la session de paiement: ${error.message}`);
    } finally {
      setIsLoading(false); // Désactiver le chargement
      setIsModalOpen(false); // Fermer la modal
    }
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-[#3399FF]">Tarifs</h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Des prix pour tous les restaurateurs
            </p>
            <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
              Un abonnement mensuel sans engagement, vous pouvez changer de plan à tout moment. Tous nos plans incluent un essai gratuit de 30 jours.
            </p>
            <div className="mt-16 flex justify-center">
              <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
              >
                <RadioGroup.Label className="sr-only">Fréquence de paiement</RadioGroup.Label>
                {frequencies.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                      classNames(
                        checked ? 'bg-[#003366] text-white' : 'text-gray-500',
                        'cursor-pointer rounded-full px-2.5 py-1'
                      )
                    }
                  >
                    {option.label}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.featured ? 'bg-[#003366] ring-gray-900' : 'ring-gray-200',
                    'rounded-3xl p-8 ring-1 xl:p-10 flex flex-col items-center'
                  )}
                >
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.featured ? 'text-white' : 'text-gray-900',
                      'text-lg font-semibold leading-8'
                    )}
                  >
                    {tier.name}
                  </h3>
                  <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6 text-center')}>
                    {tier.description}
                  </p>
                  <p className="mt-6 flex flex-col items-center">
                    <span
                      className={classNames(
                        tier.featured ? 'text-white' : 'text-gray-900',
                        'text-4xl font-bold tracking-tight'
                      )}
                    >
                      {typeof tier.price === 'string' ? tier.price : tier.price[frequency.value]}
                    </span>
                    {typeof tier.price !== 'string' ? (
                      <span
                        className={classNames(
                          tier.featured ? 'text-gray-300' : 'text-gray-600',
                          'text-sm font-semibold leading-6'
                        )}
                      >
                        {frequency.priceSuffix}
                      </span>
                    ) : null}
                  </p>
                  <button
                    onClick={() => handleSubscription(tier.priceId[frequency.value])}
                    aria-describedby={tier.id}
                    className={classNames(
                      tier.featured
                        ? 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white'
                        : 'bg-[#003366] text-white shadow-sm hover:bg-[#3399FF] focus-visible:outline-indigo-600',
                      'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                    )}
                    disabled={isLoading} // Désactiver le bouton si isLoading est vrai
                  >
                    {isLoading ? 'Chargement...' : tier.cta} {/* Afficher le texte de chargement */}
                  </button>
                  <ul
                    className={classNames(
                      tier.featured ? 'text-gray-300' : 'text-gray-600',
                      'mt-8 space-y-3 text-sm leading-6 xl:mt-10 text-center'
                    )}
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className={classNames(tier.featured ? 'text-white' : 'text-indigo-600', 'h-6 w-5 flex-none')}
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {/* Modal de chargement */}
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="flex justify-center">
                    <svg className="animate-spin h-10 w-10 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Traitement en cours
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Votre demande est en cours de traitement. Veuillez patienter un moment.
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default Subscribe;
