import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipboardList, BookOpen, ChefHat, Bot } from 'lucide-react';

const Button = ({ children, className, variant, onClick }) => (
  <button
    onClick={onClick}
    className={`px-6 py-3.5 rounded-xl text-base font-semibold  leading-normal ${
      variant === 'outline'
        ? 'bg-white border border-black text-black hover:bg-gray-100'
        : 'bg-black text-white hover:bg-gray-800'
    } ${className}`}
  >
    {children}
  </button>
);

export function SectionHero() {
  const navigate = useNavigate();

  return (
    <div className="relative bg-[#dbeefa] min-h-screen overflow-hidden px-4 sm:px-6 lg:px-8">
      <div className="max-w-[1636px] mx-auto">
        <div className="pt-20 lg:pt-36 pb-12 lg:pb-16 max-w-4xl mx-auto ml-[10%]">
          <h1 className="text-[#212121] text-left text-4xl sm:text-5xl lg:text-[56px] font-bold leading-tight lg:leading-[60px] mb-4">
            Une solution simple pour réaliser vos obligations sanitaires.
          </h1>
          <p className="text-[#666666] text-left text-lg sm:text-xl  leading-normal mb-8">
            Simplifiez la gestion de vos règles d'hygiène grâce à notre solution mobile et web. Profitez de notre assistant intelligent pour répondre à vos questions.
          </p>
          <div className="flex items-left gap-6">
            <Button onClick={() => navigate('/mobile-app')}>Télécharger l'application</Button>
          </div>
        </div>

        <div className="flex flex-col mt-[7%] lg:flex-row lg:space-x-8 pb-16 ml-[7%]">
          {/* Quick Questions */}
          <div className="lg:w-[705px] mb-8 lg:mb-0">
            <div className="bg-white rounded-[45px] p-4 mb-6 inline-flex items-center">
              <div className="w-[30px] h-[30px] bg-gradient-to-t from-[#4a25e1] to-[#7a5aff] rounded-full mr-2 flex items-center justify-center">
                <Bot size={20} className="text-white" />
              </div>
              <div className="text-[#1b2559] text-sm font-semibold font-['Plus Jakarta Sans']">
                Comment puis-je vous aider aujourd'hui ?
              </div>
            </div>
            <div className="space-y-4 ">
              {[
                { icon: ClipboardList, text: 'Gestion des temperatures' },
                { icon: BookOpen, text: "Normes d'hygiène" },
                { icon: ChefHat, text: 'Contrôle à la réception' },
              ].map((item, index) => (
                <div
                  key={index}
                  className="h-[42px] rounded-[45px] border border-[#1b2559]/20 flex items-center justify-center gap-2.5 hover:bg-white hover:shadow-md transition-all cursor-pointer"
                >
                  <item.icon size={20} className="text-[#1b2559]" />
                  <div className="text-[#1b2559] text-sm font-bold ">{item.text}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Chat Interface */}
          <div className="lg:w-[750px]  lg:relative">
            <div className="bg-white rounded-[10.50px] ml-[10%] shadow overflow-hidden lg:absolute lg:left-0 lg:right-[-75px]">
              <div className="h-[45px] px-4 border-b border-gray-200 flex items-center">
                <div className="text-[#1b2559] text-xs font-semibold font-['Plus Jakarta Sans']">
                  Quelles sont mes obligations légales à respecter ?
                </div>
              </div>
              <div className="h-[355.50px] p-4 overflow-y-auto">
                <div className="text-[#1b2559] text-left text-xs sm:text-sm font-semibold font-['Plus Jakarta Sans'] leading-relaxed ">
                  Voici un résumé des principales obligations légales pour être en régle dans un
                  restaurant :
                  <br />
                  <br />
                  1. Traçabilité des aliments : Vous devez pouvoir tracer l'origine de tous vos
                  produits.
                  <br />
                  2. Respect des dates de péremption : Assurez-vous de ne pas utiliser de produits
                  périmés.
                  <br />
                  3. Contrôle des températures : Verifier que la chaine du froid n'est pas
                  interrompu.
                  <br />
                  <br />
                  Assurez-vous de consulter les réglementations locales pour plus de détails et de
                  rester à jour avec les changements législatifs.
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="absolute left-[-45px] ml-[10%] top-[60px]">
                <div className="w-[30px] h-[30px] bg-gradient-to-t from-[#4a25e1]  to-[#7a5aff] rounded-full flex items-center justify-center">
                  <Bot size={20} className="text-white" />
                </div>
              </div>
              <img
                className="w-[30px] ml-[10%] h-[30px] absolute left-[-45px] top-[7.50px] rounded-full border border-slate-200"
                src="avatar.png"
                alt="User avatar"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Gradient Overlay */}
      <div className="absolute bottom-0 left-0 right-0 h-[172px] bg-gradient-to-b from-transparent to-[#dbeefa]"></div>
    </div>
  );
}
