import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, query, collection, getDocs, where } from 'firebase/firestore';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [enterpriseId, setEnterpriseId] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [userRole, setUserRole] = useState('member');
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [accessibleEnterprises, setAccessibleEnterprises] = useState([]); // Entreprises accessibles pour admin
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        const userDocRef = doc(db, 'users', firebaseUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const { enterpriseID, organizationID, role } = userData;

          setEnterpriseId(enterpriseID || '');
          setOrganizationId(organizationID || '');
          setUserRole(role || 'member');

          // Récupérer les informations d'abonnement et des entreprises en fonction du rôle de l'utilisateur
          if (role === 'admin' && organizationID) {
            // Si l'utilisateur est un admin, récupérer les entreprises associées à l'organisation
            const orgDocRef = doc(db, 'organizations', organizationID);
            const orgDoc = await getDoc(orgDocRef);
            if (orgDoc.exists() && orgDoc.data().subscriptionActive) {
              setSubscriptionActive(true);
              // Ajoutez la logique ici pour récupérer les entreprises associées à l'organisation
              // (par exemple, via une collection `enterprises` liée à l'organisation)
              // Exemple simplifié (vous pouvez l'adapter selon votre structure de Firestore) :
              const enterprisesSnapshot = await getDocs(
                query(
                  collection(db, 'enterprises'),
                  where('organizationId', '==', organizationID)
                )
              );
              const enterprises = enterprisesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
              }));
              setAccessibleEnterprises(enterprises); // Stocker les entreprises accessibles
            } else {
              setSubscriptionActive(false);
            }
          } else if (role === 'member' && enterpriseID) {
            // Si l'utilisateur est un membre, récupérer les informations de son entreprise
            const enterpriseRef = doc(db, 'enterprises', enterpriseID);
            const enterpriseSnap = await getDoc(enterpriseRef);
            if (enterpriseSnap.exists() && enterpriseSnap.data().subscriptionActive) {
              setSubscriptionActive(true);
            } else {
              setSubscriptionActive(false);
            }
          } else {
            setSubscriptionActive(false); // Aucun abonnement actif si rien n'est trouvé
          }
        }
      } else {
        // Réinitialiser si l'utilisateur se déconnecte ou en cas de problème
        setUser(null);
        setEnterpriseId('');
        setOrganizationId('');
        setUserRole('');
        setSubscriptionActive(false);
      }
      setIsLoading(false); // Terminer le chargement après la récupération des données
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        enterpriseId,
        organizationId,
        userRole,
        subscriptionActive,
        accessibleEnterprises, // Entreprises accessibles pour admin
        isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
