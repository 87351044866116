// components/ReceptionOverview.js

import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

function ReceptionOverview({ selectedEnterpriseId }) {
  const [receptions, setReceptions] = useState([]);

  useEffect(() => {
    if (!selectedEnterpriseId) return;

    const receptionCollectionRef = collection(db, 'receptionInfo');
    const q = query(
      receptionCollectionRef,
      where('enterpriseId', '==', selectedEnterpriseId),
      where('archive', '==', false),
      orderBy('receptionDate', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        receptionDate: doc.data().receptionDate?.toDate(),
      }));
      setReceptions(items);
    });

    return () => unsubscribe();
  }, [selectedEnterpriseId]);

  const today = new Date().toISOString().slice(0, 10);

  const receptionsNonArchived = receptions.filter((reception) => !reception.archive);
  const nonConformReceptions = receptionsNonArchived.filter((reception) => reception.nonConformity);
  const conformReceptions = receptionsNonArchived.filter((reception) => !reception.nonConformity);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
        <h3 className="text-2xl font-bold text-gray-900 mb-6">Dernières réceptions de marchandise</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {['Contrôlé par', 'Fournisseur', 'Température', 'Date', 'Statut'].map((header) => (
                  <th
                    key={header}
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {(nonConformReceptions.length > 0 ? nonConformReceptions : conformReceptions).map(
                (reception) => (
                  <tr key={reception.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
                      {reception.User}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {reception.supplierName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {reception.productTemperature}°C
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {reception.receptionDate
                        ? reception.receptionDate.toLocaleDateString('fr-FR')
                        : 'N/A'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                      {reception.nonConformity ? (
                        <div className="flex items-center justify-center text-red-500">
                          <ExclamationCircleIcon className="h-5 w-5 mr-1" />
                          <span>Non conforme</span>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center text-green-500">
                          <CheckCircleIcon className="h-5 w-5 mr-1" />
                          <span>Conforme</span>
                        </div>
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

ReceptionOverview.propTypes = {
  selectedEnterpriseId: PropTypes.string.isRequired,
};

export default ReceptionOverview;
