import React, { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';

const frequencies = [
  { value: 'monthly', label: 'Mensuel', priceSuffix: '/mois' },
  { value: 'annually', label: 'Annuel', priceSuffix: '/an' },
];

const tiers = [
  {
    name: 'Café',
    id: 'tier-cafe',
    href: '/signup',
    price: { monthly: 29, annually: 305 },
    description: 'Gérer votre activité simplement.',
    features: ['8 utilisateurs, deux enseignes', 'Support en 24h', 'Tous les modules pour remplir vos obligations HACCP'],
    featured: false,
    cta: 'Choisir ce plan',
  },
  {
    name: 'Bistrot',
    id: 'tier-bistrot',
    href: '/signup',
    price: { monthly: 49, annually: 525 },
    description: "Idéal pour les restaurateurs avec un grand nombre d'employés ou les chaines de restaurants.",
    features: ["50 utilisateurs, jusqu'à 5 enseignes", 'Support en moins de 4h', 'Tous les modules pour remplir vos obligations HACCP'],
    featured: false,
    cta: 'Choisir ce plan',
  },
  {
    name: 'Etoilé',
    id: 'tier-grande-brasserie',
    href: '/contact',
    price: 'Sur mesure',
    description: 'Support et infrastructures dédiés pour les établissements haut de gamme. Hotelerie, grande chaine etc...',
    features: ['Utilisateurs illimités', 'Support dédié 24/7', 'Offre sur mesure construite pour vous'],
    featured: true,
    cta: 'Contacter nous',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function Pricing() {
  const [frequency, setFrequency] = useState(frequencies[0]);

  const calculateSavings = (monthly, annually) => {
    const monthlyCost = monthly * 12;
    const annualCost = annually;
    const savings = monthlyCost - annualCost;
    const percentage = ((savings / monthlyCost) * 100).toFixed(0);
    return { amount: savings, percentage };
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 pt-12 pb-20 bg-[#f4f8fa] flex flex-col items-center gap-10">
      <div className="text-center max-w-3xl">
        <h2 className="text-[#212121] text-4xl font-bold mb-2">Un plan pour chaque besoin.</h2>
        <p className="text-[#666666] text-xl">Du plus petit besoin à la demande sur mesure, nous vous proposons des offres adaptés</p>
      </div>

      <RadioGroup value={frequency} onChange={setFrequency} className="bg-white rounded-xl">
        <div className="flex">
          {frequencies.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option}
              className={({ checked }) =>
                classNames(
                  checked ? 'bg-black text-white' : 'text-black',
                  'cursor-pointer px-7 py-[15px] text-sm font-semibold tracking-wide rounded-xl'
                )
              }
            >
              {({ checked }) => (
                <span className={checked ? 'text-white' : ''}>{option.label}</span>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-7xl w-full">
        {tiers.map((tier) => {
          const price = typeof tier.price === 'string' ? tier.price : tier.price[frequency.value];
          const savings = typeof tier.price !== 'string' ? calculateSavings(tier.price.monthly, tier.price.annually) : null;

          return (
            <div
              key={tier.id}
              className={classNames(
                tier.featured ? 'bg-[#dbeefa]' : 'bg-white',
                'rounded-2xl p-6 flex flex-col justify-between'
              )}
            >
              <div>
                <div className="mb-6">
                  <span className={`inline-block px-3 py-1 rounded-2xl text-xs font-medium uppercase tracking-wide ${tier.featured ? 'bg-white text-[#212121]' : 'bg-[#dbeefa] text-[#212121]'}`}>
                    {tier.name}
                  </span>
                </div>
                <div className="text-[#212121] text-4xl font-semibold mb-2">
                  {typeof price === 'number' ? `${price}€ HT` : price}
                  {typeof price === 'number' && <span className="text-base font-normal text-[#666666]">{frequency.priceSuffix}</span>}
                </div>
                {savings && frequency.value === 'annually' && (
                  <p className="text-green-600 text-sm mb-2">
                    Économisez {savings.amount}€ ({savings.percentage}%)
                  </p>
                )}
                <p className="text-[#666666] mt-6 text-left text-base mb-6">{tier.description}</p>
              </div>
              <div>
                <ul className="space-y-3.5 mb-6">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex items-center gap-2">
                      <CheckIcon className="h-6 w-6 flex-shrink-0 text-black" />
                      <span className="text-[#666666] text-left text-base">{feature}</span>
                    </li>
                  ))}
                </ul>
                <a
                  href={tier.href}
                  className="block w-full px-6 py-3.5 bg-black text-white text-center text-base font-semibold rounded-xl"
                >
                  {tier.cta}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}