import React from 'react';
import { FaApple, FaGooglePlay } from 'react-icons/fa';

export default function MobileAppPresentation() {
  return (
    <div className="bg-gray-50">
      {/* Section Héro */}
      <div className="relative overflow-hidden">
        <div className="mx-auto max-w-7xl px-6 py-16">
          <div className="lg:flex lg:items-center lg:justify-between lg:px-8">
            {/* Section Texte */}
            <div className="lg:w-1/2">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Téléchargez notre Application Mobile
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Disponible sur iOS et Android. Gérez vos obligations sanitaires où que vous soyez avec notre application mobile intuitive.
              </p>
              {/* Section Téléchargements */}
              <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 gap-8">
                {/* iOS */}
                <div className="flex flex-col items-center">
                  <a
                    href="https://apps.apple.com/us/app/logirestau/id6504951707"
                    className="inline-flex items-center justify-center px-4 py-3 mb-4 border border-transparent text-base font-medium rounded-md text-white bg-black hover:bg-gray-800 w-full"
                  >
                    <FaApple className="h-6 w-6 mr-2" />
                    Télécharger sur l'App Store
                  </a>
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">Scannez pour iOS</h2>
                  <img src="/qr-code-ios.png" alt="QR Code iOS" className="w-40 h-40" />
                </div>
                {/* Android */}
                <div className="flex flex-col items-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.logirestau.app"
                    className="inline-flex items-center justify-center px-4 py-3 mb-4 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 w-full"
                  >
                    <FaGooglePlay className="h-6 w-6 mr-2" />
                    Télécharger sur Google Play
                  </a>
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">Scannez pour Android</h2>
                  <img src="/qr-code-android.png" alt="QR Code Android" className="w-40 h-40" />
                </div>
              </div>
            </div>
            {/* Section Image */}
            <div className="mt-12 lg:mt-0 lg:w-1/2 flex justify-center">
              <img
                src="/Screenshot_dlc.png"
                alt="Capture d'écran de l'application mobile"
                className="w-80 h-auto rounded-lg shadow-xl"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Section Applications */}
    </div>
  );
}
