// components/ReceptionDashboard.js

import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  updateDoc,
  doc,
} from 'firebase/firestore';
import PropTypes from 'prop-types';
import ModalReception from './ModalReception';

function ReceptionDashboard({ selectedEnterpriseId }) {
  const [receptions, setReceptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReception, setSelectedReception] = useState(null);
  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    if (!selectedEnterpriseId) return;

    const fetchData = async () => {
      const q = query(
        collection(db, 'receptionInfo'),
        where('enterpriseId', '==', selectedEnterpriseId),
        orderBy('receptionDate', 'desc')
      );
      const querySnapshot = await getDocs(q);
      const items = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        receptionDate: doc.data().receptionDate?.toDate(),
      }));
      setReceptions(items);
    };

    fetchData();
  }, [selectedEnterpriseId]);

  const handleSelectReception = (item, event) => {
    event.stopPropagation();
    setSelectedReception(item);
  };

  const handleCloseModal = () => {
    setSelectedReception(null);
  };

  const handleArchiveReception = async (id) => {
    const receptionDoc = doc(db, 'receptionInfo', id);
    await updateDoc(receptionDoc, { archive: true });
    setReceptions(
      receptions.map((reception) =>
        reception.id === id ? { ...reception, archive: true } : reception
      )
    );
  };

  const filteredReceptions = receptions.filter((reception) => {
    return (
      (showArchived || !reception.archive) &&
      (!searchTerm ||
        (reception.User &&
          reception.User.toLowerCase().includes(searchTerm.toLowerCase())))
    );
  });

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Gestion des Réceptions</h1>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-4 sm:space-y-0">
        <input
          type="text"
          placeholder="Rechercher par utilisateur"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full sm:w-1/3 border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={showArchived}
            onChange={() => setShowArchived(!showArchived)}
            className="form-checkbox h-5 w-5 text-indigo-600"
          />
          <span className="ml-2 text-sm text-gray-700">
            Afficher les archivées
          </span>
        </label>
      </div>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {[
                  'Conformité',
                  'Utilisateur',
                  'Fournisseur',
                  'Date de réception',
                  'Température',
                  'Actions',
                ].map((header) => (
                  <th
                    key={header}
                    scope="col"
                    className="px-6 py-3 text-left text-sm font-semibold text-gray-900 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredReceptions.map((item) => (
                <tr
                  key={item.id}
                  className="cursor-pointer hover:bg-gray-50"
                  onClick={(e) => handleSelectReception(item, e)}
                >
                  <td className="px-6 py-4 text-sm text-left">
                    {item.nonConformity ? (
                      <span className="inline-block rounded-full bg-red-500 px-3 py-1 text-white text-xs">
                        Non Conforme
                      </span>
                    ) : (
                      <span className="inline-block rounded-full bg-green-500 px-3 py-1 text-white text-xs">
                        Conforme
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 text-sm text-left text-gray-900">
                    {item.User || 'N/A'}
                  </td>
                  <td className="px-6 py-4 text-sm text-left text-gray-900">
                    {item.supplierName || 'N/A'}
                  </td>
                  <td className="px-6 py-4 text-sm text-left text-gray-500">
                    {item.receptionDate
                      ? item.receptionDate.toLocaleDateString('fr-FR')
                      : 'N/A'}
                  </td>
                  <td className="px-6 py-4 text-sm text-left text-gray-500">
                    {item.productTemperature !== null
                      ? `${item.productTemperature} °C`
                      : 'N/A'}
                  </td>
                  <td className="px-6 py-4 text-left text-sm space-x-4">
                    <button
                      onClick={(e) => handleSelectReception(item, e)}
                      className="text-indigo-600 hover:text-indigo-900 font-medium"
                    >
                      Détails
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleArchiveReception(item.id);
                      }}
                      className="text-red-600 hover:text-red-900 font-medium"
                    >
                      Archiver
                    </button>
                  </td>
                </tr>
              ))}
              {filteredReceptions.length === 0 && (
                <tr>
                  <td
                    colSpan="6"
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                  >
                    Aucune réception trouvée.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {selectedReception && (
        <ModalReception
          isOpen={!!selectedReception}
          onClose={handleCloseModal}
          reception={selectedReception}
        />
      )}
    </div>
  );
}

ReceptionDashboard.propTypes = {
  selectedEnterpriseId: PropTypes.string.isRequired,
};

export default ReceptionDashboard;
