// components/EstablishmentModal.js

import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { db } from '../firebase';
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  query,
  where,
  getDocs,
  writeBatch,
} from 'firebase/firestore';
import { useUser } from '../contexts/UserContext';

function EstablishmentModal({ isOpen, onClose, onEstablishmentAdded, maxEstablishments, currentEstablishmentCount, enterpriseId }) {
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const { user } = useUser();

  useEffect(() => {
    setError('');
    setName('');
  }, [isOpen]);

  const handleAddEstablishment = async () => {
    if (!name.trim()) {
      setError('Veuillez saisir un nom pour l\'établissement.');
      return;
    }

    if (currentEstablishmentCount >= maxEstablishments) {
      setError('Vous avez atteint le nombre maximal d\'établissements autorisés.');
      return;
    }

    try {
      // Récupérer les données de l'entreprise actuelle
      const enterpriseRef = doc(db, 'enterprises', enterpriseId);
      const enterpriseSnap = await getDoc(enterpriseRef);
      if (!enterpriseSnap.exists()) {
        setError('Entreprise non trouvée.');
        return;
      }
      const enterpriseData = enterpriseSnap.data();

      // Vérifier ou créer organizationId
      let organizationId = enterpriseData.organizationId || null;
      if (!organizationId) {
        // Créer une nouvelle organisation
        const orgRef = await addDoc(collection(db, 'organizations'), {
          name: enterpriseData.name || enterpriseId,
          subscriptionActive: enterpriseData.subscriptionActive,
          userLimit: enterpriseData.userLimit,
        });
        organizationId = orgRef.id;

        // Mettre à jour l'entreprise actuelle avec l'organisationId
        await updateDoc(enterpriseRef, { organizationId });

        // Mettre à jour les utilisateurs associés à cette entreprise
        const usersQuery = query(
          collection(db, 'users'),
          where('enterpriseID', '==', enterpriseId)
        );
        const usersSnapshot = await getDocs(usersQuery);
        const batch = writeBatch(db);
        usersSnapshot.forEach((docSnap) => {
          const userRef = doc(db, 'users', docSnap.id);
          batch.update(userRef, { organizationID: organizationId });
        });
        await batch.commit();
      }

      // Créer un nouvel établissement (entreprise)
      const newEnterpriseRef = await addDoc(collection(db, 'enterprises'), {
        name: name.trim(),
        subscriptionActive: enterpriseData.subscriptionActive,
        userLimit: enterpriseData.userLimit,
        organizationId,
      });

      // Appeler le callback pour informer le parent qu'un établissement a été ajouté
      if (onEstablishmentAdded) {
        onEstablishmentAdded();
      }

      onClose();
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'établissement:', error);
      setError('Une erreur est survenue lors de la création de l\'établissement.');
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {/* Contenu du modal */}
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30">
        <div className="bg-white rounded-lg p-6 w-96">
          <Dialog.Title className="text-lg font-medium text-gray-900">
            Ajouter un établissement
          </Dialog.Title>
          <div className="mt-2">
            {error && <p className="text-red-500">{error}</p>}
            <div className="mt-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Nom de l'établissement
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#3399FF] focus:ring-[#3399FF] sm:text-sm"
                placeholder="Entrez le nom de l'établissement"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
            >
              Annuler
            </button>
            <button
              onClick={handleAddEstablishment}
              className="px-4 py-2 bg-[#3399FF] text-white rounded-md"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default EstablishmentModal;
