// components/AddFridgeModal.js

import React, { useState, useEffect } from 'react';
import { BiSolidFridge  } from "react-icons/bi";
import { IoIosSnow } from "react-icons/io";



function AddFridgeModal({ isOpen, onClose, onAdd }) {
    const [name, setName] = useState('');
    const [type, setType] = useState('Frigo'); // Type par défaut
    const [error, setError] = useState('');

    useEffect(() => {
      if (isOpen) {
        setName('');
        setType('Frigo');
        setError('');
      }
    }, [isOpen]);

    const handleSubmit = () => {
      if (!name.trim()) {
        setError('Le nom de l\'appareil ne peut pas être vide.');
        return;
      }
      onAdd(name.trim(), type);
      setName('');          // Réinitialiser le nom
      setType('Frigo');     // Réinitialiser le type
      setError('');
      onClose();            // Fermer le modal
    };
  
    if (!isOpen) return null;

    // SVG personnalisés pour Frigo et Congélateur
    const FrigoIcon = () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-gray-700"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <rect x="3" y="7" width="18" height="14" rx="2" ry="2" />
        <path d="M8 7V4a1 1 0 011-1h6a1 1 0 011 1v3" />
        <path d="M16 11H8M16 15H8M16 19H8" />
      </svg>
    );

    const CongelateurIcon = () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-gray-700"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path d="M3 9h18v10H3V9z" />
        <path d="M7 9V5a1 1 0 011-1h8a1 1 0 011 1v4" />
        <path d="M7 13h10M7 17h10" />
      </svg>
    );

    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg shadow-xl p-6 w-96">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Ajouter un nouvel appareil</h3>
          
          {/* Champ de saisie pour le nom de l'appareil */}
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nom de l'appareil"
            className="border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 rounded-md shadow-sm w-full p-2"
          />
          
          {/* Sélection du type d'appareil */}
          <div className="mt-4">
            <p className="text-sm font-medium text-gray-700 mb-2">Type d'appareil</p>
            <div className="flex justify-between">
              
              {/* Bouton pour Frigo */}
              <button
                type="button"
                onClick={() => setType('Frigo')}
                className={`flex flex-col items-center justify-center p-4 border rounded-md flex-1 mx-1 transition-colors duration-200 ${
                  type === 'Frigo' ? 'border-blue-500 bg-blue-100' : 'border-gray-300 hover:border-blue-500 hover:bg-blue-50'
                }`}
              >
                <BiSolidFridge  />
                <span className="mt-2 text-sm">Frigo</span>
              </button>
              
              {/* Bouton pour Congélateur */}
              <button
                type="button"
                onClick={() => setType('Congélateur')}
                className={`flex flex-col items-center justify-center p-4 border rounded-md flex-1 mx-1 transition-colors duration-200 ${
                  type === 'Congélateur' ? 'border-blue-500 bg-blue-100' : 'border-gray-300 hover:border-blue-500 hover:bg-blue-50'
                }`}
              >
                <IoIosSnow />
                <span className="mt-2 text-sm">Congélateur</span>
              </button>
              
            </div>
          </div>
          
          {/* Affichage des erreurs */}
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          
          {/* Boutons d'action */}
          <div className="mt-6 flex justify-end gap-4">
            <button
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Ajouter
            </button>
            <button
              onClick={onClose}
              className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded"
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    );
}

export default AddFridgeModal;
