// components/OilCheckHistoryPage.js

import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { db } from '../firebase';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import PropTypes from 'prop-types';

function OilCheckHistoryPage({ selectedEnterpriseId }) { // Accepter selectedEnterpriseId en tant que prop
  const [oilChecks, setOilChecks] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFriteuseChecks, setSelectedFriteuseChecks] = useState([]);

  useEffect(() => {
    if (!selectedEnterpriseId) return;

    const fetchOilChecks = async () => {
      const q = query(
        collection(db, 'oilcheck'),
        where('enterpriseId', '==', selectedEnterpriseId)
      );

      try {
        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.checks && data.checks.length > 0) {
            items.push({
              id: doc.id,
              name: data.name,
              lastCheckDate: data.checks[data.checks.length - 1].date,
              checks: data.checks,
            });
          }
        });

        // Trier les éléments par date de dernier contrôle décroissante
        items.sort((a, b) => {
          const dateA = a.lastCheckDate.toDate();
          const dateB = b.lastCheckDate.toDate();
          return dateB - dateA;
        });
        setOilChecks(items);
      } catch (error) {
        console.error('Erreur lors de la récupération des contrôles des huiles:', error);
      }
    };

    fetchOilChecks();
  }, [selectedEnterpriseId]);

  const formatDate = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      return timestamp.toDate().toLocaleString('fr-FR');
    }
    return 'N/A';
  };

  const getColor = (date) => {
    if (!date) return 'bg-red-500';

    const now = new Date();
    const lastCheck = date.toDate();
    const difference = Math.floor((now - lastCheck) / (1000 * 60 * 60 * 24));

    if (difference < 7) return 'bg-green-500';
    if (difference < 14) return 'bg-orange-500';
    return 'bg-red-500';
  };

  const openModal = (checks) => {
    setSelectedFriteuseChecks(checks);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedFriteuseChecks([]);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Historique des contrôles des huiles</h1>
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 uppercase tracking-wider"
                >
                  Nom de la friteuse
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-sm font-semibold text-gray-900 uppercase tracking-wider"
                >
                  Date et Heure du dernier contrôle
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left text-sm font-semibold text-gray-900 uppercase tracking-wider"
                >
                  État
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center text-sm font-semibold text-gray-900 uppercase tracking-wider"
                >
                  Voir l'historique
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {oilChecks.map((item) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-left font-medium text-gray-900">
                    {item.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                    {formatDate(item.lastCheckDate)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-left">
                    <span
                      className={`inline-flex px-2.5 py-0.5 rounded text-xs font-medium ${
                        item.lastCheckDate ? getColor(item.lastCheckDate) : 'bg-red-500'
                      }`}
                    >
                      {item.lastCheckDate ? 'Contrôlé' : 'Non contrôlé'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
                    <button
                      onClick={() => openModal(item.checks)}
                      className="bg-blue-500 hover:bg-[#003366] text-white font-bold py-2 px-4 rounded-full"
                    >
                      Voir l'historique
                    </button>
                  </td>
                </tr>
              ))}
              {oilChecks.length === 0 && (
                <tr>
                  <td colSpan="4" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                    Aucune donnée disponible.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal */}
      <Transition.Root show={modalIsOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Historique des contrôles
                      </Dialog.Title>
                      <div className="mt-2">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-3 py-3 text-left text-sm font-semibold text-gray-900 uppercase tracking-wider"
                              >
                                Date et Heure
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3 text-left text-sm font-semibold text-gray-900 uppercase tracking-wider"
                              >
                                Contrôlé
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {selectedFriteuseChecks.map((check, index) => (
                              <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                                  {formatDate(check.date)}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-left text-gray-500">
                                  {check.checked ? 'Oui' : 'Non'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                      onClick={closeModal}
                    >
                      Fermer
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

OilCheckHistoryPage.propTypes = {
  selectedEnterpriseId: PropTypes.string.isRequired, // Validation de la prop
};

export default OilCheckHistoryPage;