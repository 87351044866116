// components/Dashboard.js

import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition, Combobox } from '@headlessui/react';
import { FaTemperatureFull } from "react-icons/fa6";
import { CiFries, CiDeliveryTruck } from "react-icons/ci";
import {
  HomeIcon,
  UsersIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  XMarkIcon,
  Bars3Icon,
  ArrowRightOnRectangleIcon,
  CheckIcon,
  ChevronUpDownIcon,
  PlusIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { Logo } from '../components/Logo';
import { LogoName } from '../components/LogoName';
import { Settings } from './Settings';
import Team from './Team';
import Overview from './Overview';
import FridgeManagement from './FridgeManagement';
import DLCDashboard from './DLCDashboard';
import ReceptionDashboard from './ReceptionDashboard';
import { useUser } from '../contexts/UserContext';
import CleaningPlanOverview from './CleaningPlanOverview';
import OilCheckPage from './OilDashboard';
import { auth, db } from '../firebase';
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import EstablishmentModal from './EstablishmentModal'; // Import du modal

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const navigation = [
  { name: 'Dashboard', href: '#', icon: HomeIcon },
  { name: 'Equipe', href: '#', icon: UsersIcon },
  { name: 'DLC', href: '#', icon: CalendarDaysIcon },
  { name: 'Relevé temperature', href: '#', icon: FaTemperatureFull },
  { name: 'Marchandises', href: '#', icon: CiDeliveryTruck },
  { name: "Plan d'hygiene", href: '#', icon: ChartPieIcon },
  { name: "Huiles", href: '#', icon: CiFries },
];

export function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('Dashboard');

  const {
    enterpriseId,
    organizationId,
    userRole,
    user,
  } = useUser();

  const [accessibleEnterprises, setAccessibleEnterprises] = useState([]);
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState(enterpriseId);
  const [queryStr, setQueryStr] = useState('');
  const [filteredEnterprises, setFilteredEnterprises] = useState([]);
  const [editingEstablishmentId, setEditingEstablishmentId] = useState(null);
  const [newEstablishmentName, setNewEstablishmentName] = useState('');
  const [maxEstablishments, setMaxEstablishments] = useState(1);
  const [currentEstablishmentCount, setCurrentEstablishmentCount] = useState(0);

  // État pour gérer le modal
  const [isEstablishmentModalOpen, setIsEstablishmentModalOpen] = useState(false);

  const handleTabChange = (tabName) => {
    setCurrentTab(tabName);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.href = '/login';
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  // Fonction pour récupérer les entreprises accessibles
  const fetchEnterprises = async () => {
    try {
      // Utiliser le contexte utilisateur pour récupérer les informations nécessaires
  
      if (!enterpriseId) return; // Si l'enterpriseId n'est pas défini, sortir
  
      // Si l'utilisateur est un admin et qu'il y a un organizationId, récupérer les entreprises de l'organisation
      if (userRole === 'admin' && organizationId) {
        // Récupérer les entreprises de l'organisation
        const enterprisesSnapshot = await getDocs(
          query(
            collection(db, 'enterprises'),
            where('organizationId', '==', organizationId)
          )
        );
  
        const enterprises = enterprisesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        setAccessibleEnterprises(enterprises);
        setCurrentEstablishmentCount(enterprises.length);
  
        // Si aucun établissement n'est sélectionné, sélectionner le premier
        if (!selectedEnterpriseId && enterprises.length > 0) {
          setSelectedEnterpriseId(enterprises[0].id);
        }
      } else if (userRole === 'member') {
        // Si l'utilisateur est un membre, ne montrer que son enterpriseId
        const enterpriseRef = doc(db, 'enterprises', enterpriseId);
        const enterpriseSnap = await getDoc(enterpriseRef);
  
        if (enterpriseSnap.exists()) {
          const enterpriseData = {
            id: enterpriseRef.id,
            ...enterpriseSnap.data(),
          };
  
          setAccessibleEnterprises([enterpriseData]);
          setCurrentEstablishmentCount(1);
          setSelectedEnterpriseId(enterpriseRef.id);
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des entreprises:', error);
    }
  };

  useEffect(() => {
    fetchEnterprises();
  }, [enterpriseId]);

  const selectedEnterprise = accessibleEnterprises.find(
    (enterprise) => enterprise.id === selectedEnterpriseId
  );

  // Filtrer les entreprises en fonction de la requête
  useEffect(() => {
    if (queryStr === '') {
      setFilteredEnterprises(accessibleEnterprises);
    } else {
      setFilteredEnterprises(
        accessibleEnterprises.filter((enterprise) =>
          (enterprise.name || 'Mon enseigne').toLowerCase().includes(queryStr.toLowerCase())
        )
      );
    }
  }, [queryStr, accessibleEnterprises]);

  const handleEditEstablishmentName = async (enterpriseId) => {
    if (!newEstablishmentName.trim()) {
      // Nom vide, annuler l'édition
      setEditingEstablishmentId(null);
      return;
    }
    try {
      const enterpriseRef = doc(db, 'enterprises', enterpriseId);
      await updateDoc(enterpriseRef, { name: newEstablishmentName.trim() });
      // Mettre à jour l'état local
      setAccessibleEnterprises(prev =>
        prev.map(ent =>
          ent.id === enterpriseId ? { ...ent, name: newEstablishmentName.trim() } : ent
        )
      );
      setEditingEstablishmentId(null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du nom de l\'établissement:', error);
      // Gérer l'erreur si nécessaire
    }
  };

  // Fonction pour rafraîchir les entreprises après la création via le modal
  const refreshEnterprises = () => {
    fetchEnterprises();
  };

  return (
    <>
      <div>
        {/* Sidebar Mobile */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Contenu de la sidebar mobile */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <Logo className="h-8 w-auto" />
                      <LogoName className="ml-3 mt-1" />
                    </div>
                    {/* Sélecteur d'établissement */}
                    <div className="mt-4">
                      {accessibleEnterprises.length >= 1 ? (
                        <Combobox
                          as="div"
                          value={selectedEnterprise}
                          onChange={(enterprise) => {
                            if (enterprise) {
                              if (enterprise.id === 'add_new') {
                                // Ouvrir le modal pour ajouter un nouvel établissement
                                setIsEstablishmentModalOpen(true);
                              } else {
                                setSelectedEnterpriseId(enterprise.id);
                                setSidebarOpen(false); // Fermer la sidebar après sélection
                              }
                            }
                          }}
                        >
                          <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                            Établissement
                          </Combobox.Label>
                          <div className="relative mt-2">
                            <Combobox.Input
                              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-[#3399FF] sm:text-sm sm:leading-6"
                              displayValue={(enterprise) => enterprise?.name || 'Mon enseigne'}
                              onChange={(event) => setQueryStr(event.target.value)}
                              placeholder="Sélectionnez un établissement"
                              readOnly
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Combobox.Button>
                            {(filteredEnterprises.length > 0 || queryStr !== '') && (
                              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredEnterprises.map((enterprise) => (
                                  <Combobox.Option
                                    key={enterprise.id}
                                    value={enterprise}
                                    className={({ active }) =>
                                      classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        active ? 'bg-[#3399FF] text-white' : 'text-gray-900'
                                      )
                                    }
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        {editingEstablishmentId === enterprise.id ? (
                                          // Champ de saisie pour modifier le nom
                                          <input
                                            type="text"
                                            value={newEstablishmentName}
                                            onChange={(e) => setNewEstablishmentName(e.target.value)}
                                            onBlur={() => handleEditEstablishmentName(enterprise.id)}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                handleEditEstablishmentName(enterprise.id);
                                              }
                                            }}
                                            className="w-full bg-transparent focus:outline-none"
                                            autoFocus
                                          />
                                        ) : (
                                          <span
                                            className={classNames(
                                              'block truncate',
                                              selected ? 'font-semibold' : ''
                                            )}
                                          >
                                            {enterprise.name || 'Mon enseigne'}
                                          </span>
                                        )}
                                        {/* Icône crayon pour modifier */}
                                        {userRole === 'admin' && editingEstablishmentId !== enterprise.id && (
                                          <button
                                            type="button"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setEditingEstablishmentId(enterprise.id);
                                              setNewEstablishmentName(enterprise.name || '');
                                            }}
                                            className="absolute inset-y-0 right-8 flex items-center pr-4"
                                          >
                                            <PencilIcon
                                              className="h-5 w-5 text-gray-400 hover:text-gray-600"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        )}
                                        {selected ? (
                                          <span
                                            className={classNames(
                                              'absolute inset-y-0 right-0 flex items-center pr-4',
                                              active ? 'text-white' : 'text-[#3399FF]'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))}
                                {/* Option pour ajouter un nouvel établissement */}
                                {userRole === 'admin' &&
                                  queryStr === '' && ( // Afficher l'option "Ajouter" uniquement si aucune recherche n'est effectuée
                                    <Combobox.Option
                                      value={{ id: 'add_new', name: 'Ajouter un nouvel établissement' }}
                                      className={({ active }) =>
                                        classNames(
                                          'relative cursor-default select-none py-2 pl-3 pr-9',
                                          active ? 'bg-[#3399FF] text-white' : 'text-gray-900'
                                        )
                                      }
                                    >
                                      {({ active }) => (
                                        <span className="block truncate font-semibold">
                                          Ajouter un nouvel établissement
                                        </span>
                                      )}
                                    </Combobox.Option>
                                  )}
                              </Combobox.Options>
                            )}
                          </div>
                        </Combobox>
                      ) : (
                        <div className="block text-sm font-medium leading-6 text-gray-900">
                          {selectedEnterprise?.name || 'Mon enseigne'}
                        </div>
                      )}
                    </div>
                    {/* Navigation */}
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <button
                                  onClick={() => handleTabChange(item.name)}
                                  className={classNames(
                                    currentTab === item.name
                                      ? 'bg-gray-50 text-[#3399FF]'
                                      : 'text-gray-700 hover:text-[#3399FF] hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      currentTab === item.name ? 'text-[#3399FF]' : 'text-gray-400 group-hover:text-[#3399FF]',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </li>
                        {/* ... autres éléments de navigation ... */}
                        <li className="mt-auto">
                          <button
                            onClick={() => handleTabChange('Settings')}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-[#3399FF]"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[#3399FF]"
                              aria-hidden="true"
                            />
                            Paramètres
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={handleLogout}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600"
                          >
                            <ArrowRightOnRectangleIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
                              aria-hidden="true"
                            />
                            Déconnexion
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Sidebar pour les écrans larges */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <Logo className="h-8 w-auto" />
              <LogoName className="ml-3 mt-1" />
            </div>
            {/* Sélecteur d'établissement */}
            <div className="mt-4">
              {accessibleEnterprises.length >= 1 ? (
                <Combobox
                  as="div"
                  value={selectedEnterprise}
                  onChange={(enterprise) => {
                    if (enterprise) {
                      if (enterprise.id === 'add_new') {
                        // Ouvrir le modal pour ajouter un nouvel établissement
                        setIsEstablishmentModalOpen(true);
                      } else {
                        setSelectedEnterpriseId(enterprise.id);
                      }
                    }
                  }}
                >
                  <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                    Établissement
                  </Combobox.Label>
                  <div className="relative mt-2">
                    <Combobox.Input
                      className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-[#3399FF] sm:text-sm sm:leading-6"
                      displayValue={(enterprise) => enterprise?.name || 'Mon enseigne'}
                      onChange={(event) => setQueryStr(event.target.value)}
                      placeholder="Sélectionnez un établissement"
                      readOnly
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>
                    {(filteredEnterprises.length > 0 || queryStr !== '') && (
                      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredEnterprises.map((enterprise) => (
                          <Combobox.Option
                            key={enterprise.id}
                            value={enterprise}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                active ? 'bg-[#3399FF] text-white' : 'text-gray-900'
                              )
                            }
                          >
                            {({ selected, active }) => (
                              <>
                                {editingEstablishmentId === enterprise.id ? (
                                  // Champ de saisie pour modifier le nom
                                  <input
                                    type="text"
                                    value={newEstablishmentName}
                                    onChange={(e) => setNewEstablishmentName(e.target.value)}
                                    onBlur={() => handleEditEstablishmentName(enterprise.id)}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        handleEditEstablishmentName(enterprise.id);
                                      }
                                    }}
                                    className="w-full bg-transparent focus:outline-none"
                                    autoFocus
                                  />
                                ) : (
                                  <span
                                    className={classNames(
                                      'block truncate',
                                      selected ? 'font-semibold' : ''
                                    )}
                                  >
                                    {enterprise.name || 'Mon enseigne'}
                                  </span>
                                )}
                                {/* Icône crayon pour modifier */}
                                {userRole === 'admin' && editingEstablishmentId !== enterprise.id && (
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setEditingEstablishmentId(enterprise.id);
                                      setNewEstablishmentName(enterprise.name || '');
                                    }}
                                    className="absolute inset-y-0 right-8 flex items-center pr-4"
                                  >
                                    <PencilIcon
                                      className="h-5 w-5 text-gray-400 hover:text-gray-600"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                                {selected ? (
                                  <span
                                    className={classNames(
                                      'absolute inset-y-0 right-0 flex items-center pr-4',
                                      active ? 'text-white' : 'text-[#3399FF]'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                        {/* Option pour ajouter un nouvel établissement */}
                        {userRole === 'admin' &&
                          queryStr === '' && ( // Afficher l'option "Ajouter" uniquement si aucune recherche n'est effectuée
                            <Combobox.Option
                              value={{ id: 'add_new', name: 'Ajouter un nouvel établissement' }}
                              className={({ active }) =>
                                classNames(
                                  'relative cursor-default select-none py-2 pl-3 pr-9',
                                  active ? 'bg-[#3399FF] text-white' : 'text-gray-900'
                                )
                              }
                            >
                              {({ active }) => (
                                <span className="block truncate font-semibold">
                                  Ajouter un nouvel établissement
                                </span>
                              )}
                            </Combobox.Option>
                          )}
                      </Combobox.Options>
                    )}
                  </div>
                </Combobox>
              ) : (
                <div className="block text-sm font-medium leading-6 text-gray-900">
                  {selectedEnterprise?.name || 'Mon enseigne'}
                </div>
              )}
            </div>
            {/* Navigation */}
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <button
                          onClick={() => handleTabChange(item.name)}
                          className={classNames(
                            currentTab === item.name
                              ? 'bg-gray-50 text-[#3399FF]'
                              : 'text-gray-700 hover:text-[#3399FF] hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              currentTab === item.name ? 'text-[#3399FF]' : 'text-gray-400 group-hover:text-[#3399FF]',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
                {/* ... autres éléments de navigation ... */}
                <li className="mt-auto">
                  <button
                    onClick={() => handleTabChange('Settings')}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-[#3399FF]"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[#3399FF]"
                      aria-hidden="true"
                    />
                    Paramètres
                  </button>
                </li>
                <li>
                  <button
                    onClick={handleLogout}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-red-600"
                  >
                    <ArrowRightOnRectangleIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-red-600"
                      aria-hidden="true"
                    />
                    Déconnexion
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {/* Contenu principal */}
        <div className="lg:pl-72">
          <main className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              {currentTab === 'Settings' && <Settings />}
              {currentTab === 'DLC' && <DLCDashboard selectedEnterpriseId={selectedEnterpriseId} />}
              {currentTab === 'Equipe' && <Team selectedEnterpriseId={selectedEnterpriseId} />}
              {currentTab === 'Dashboard' && <Overview selectedEnterpriseId={selectedEnterpriseId} />}
              {currentTab === 'Relevé temperature' && <FridgeManagement selectedEnterpriseId={selectedEnterpriseId} />}
              {currentTab === 'Marchandises' && <ReceptionDashboard selectedEnterpriseId={selectedEnterpriseId} />}
              {currentTab === "Plan d'hygiene" && <CleaningPlanOverview selectedEnterpriseId={selectedEnterpriseId} />}
              {currentTab === "Huiles" && <OilCheckPage selectedEnterpriseId={selectedEnterpriseId} />}
            </div>
          </main>
        </div>

        {/* Bouton Hamburger pour ouvrir la sidebar mobile */}
        <div className="fixed top-4 left-4 lg:hidden">
          <button
            type="button"
            className="rounded-md bg-white p-2 text-gray-400 shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#3399FF]"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Ouvrir la sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* Modal pour créer un nouvel établissement */}
      <EstablishmentModal
        isOpen={isEstablishmentModalOpen}
        onClose={() => setIsEstablishmentModalOpen(false)}
        onEstablishmentAdded={() => {
          setIsEstablishmentModalOpen(false);
          refreshEnterprises(); // Rafraîchir la liste des établissements après création
        }}
        maxEstablishments={maxEstablishments}
        currentEstablishmentCount={currentEstablishmentCount}
        enterpriseId={enterpriseId} // Assurez-vous que enterpriseId est disponible
      />
    </>
  );
}

export default Dashboard;
