import React, { useEffect, useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ChevronRightIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { getUsersByEnterpriseId, getAllUsersByOrganizationId, addUserToEnterprise, updateUserRole, updateUserEnterprise, deleteUser } from '../services/UserService';
import { useUser } from '../contexts/UserContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const Team = ({ selectedEnterpriseId }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [isAddingMember, setIsAddingMember] = useState(false);
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [newMemberRole, setNewMemberRole] = useState('member');
  const [newMemberEnterpriseId, setNewMemberEnterpriseId] = useState('');
  const [isEditingMember, setIsEditingMember] = useState(false);
  const [editingMember, setEditingMember] = useState(null);
  const [editingRole, setEditingRole] = useState('');
  const [editingEnterpriseId, setEditingEnterpriseId] = useState('');
  const [enterprises, setEnterprises] = useState([]);
  const [enterpriseMap, setEnterpriseMap] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Indicateur de chargement

  const { enterpriseId, organizationId, userRole } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      // Récupérer les entreprises de l'organisation
      if (organizationId) {
        const enterprisesSnapshot = await getDocs(
          query(
            collection(db, 'enterprises'),
            where('organizationId', '==', organizationId)
          )
        );
        const enterprisesData = enterprisesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEnterprises(enterprisesData);

        // Créer une map pour un accès rapide au nom de l'entreprise
        const entMap = {};
        enterprisesData.forEach(ent => {
          entMap[ent.id] = ent.name || 'Mon enseigne';
        });
        setEnterpriseMap(entMap);
      }

      // Récupérer les membres de l'équipe en fonction de l'entreprise sélectionnée
      if (userRole === 'admin' && organizationId && selectedEnterpriseId) {
        const users = await getUsersByEnterpriseId(selectedEnterpriseId);
        setTeamMembers(users);
      } else if (enterpriseId) {
        const users = await getUsersByEnterpriseId(enterpriseId);
        setTeamMembers(users);
      }
    };

    fetchData();
  }, [selectedEnterpriseId, enterpriseId, organizationId, userRole]);

  const handleAddMember = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Démarrer l'indicateur de chargement

    try {
      if (newMemberEmail && (userRole !== 'admin' || newMemberEnterpriseId)) {
        const enterpriseToAssign = userRole === 'admin' ? newMemberEnterpriseId : enterpriseId;

        // Appel à la fonction Firebase Function via le service
        await addUserToEnterprise(newMemberEmail, enterpriseToAssign, newMemberRole);

        // Rafraîchir la liste des membres après l'ajout
        const users = userRole === 'admin'
          ? await getUsersByEnterpriseId(selectedEnterpriseId)
          : await getUsersByEnterpriseId(enterpriseId);

        setTeamMembers(users);
        setIsAddingMember(false);
        setNewMemberEmail('');
        setNewMemberRole('member');
        setNewMemberEnterpriseId('');
      } else {
        alert('Veuillez saisir un email et sélectionner une entreprise.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'ajout du membre:', error);
    } finally {
      setIsLoading(false); // Arrêter l'indicateur de chargement
    }
  };

  const handleEditMember = async (e) => {
    e.preventDefault();
    try {
      if (editingMember && editingRole && (userRole !== 'admin' || editingEnterpriseId)) {
        await updateUserRole(editingMember.id, editingRole);
        if (userRole === 'admin') {
          await updateUserEnterprise(editingMember.id, editingEnterpriseId);
        }
        const users = await getUsersByEnterpriseId(selectedEnterpriseId);
        setTeamMembers(users);
        setIsEditingMember(false);
        setEditingMember(null);
        setEditingRole('');
        setEditingEnterpriseId('');
      } else {
        alert('Veuillez sélectionner une entreprise.');
      }
    } catch (error) {
      console.error('Erreur lors de la modification du membre:', error);
    }
  };

  const handleDeleteMember = async (userId) => {
    try {
      const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?');
      if (confirmDelete) {
        await deleteUser(userId);
        const users = await getUsersByEnterpriseId(selectedEnterpriseId);
        setTeamMembers(users);
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du membre:', error);
    }
  };

  const getInitials = (email) => {
    const nameParts = email.split('@')[0].split('.');
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    }
    return nameParts.map((part) => part[0].toUpperCase()).join('');
  };

  const roleLabels = {
    member: 'Membre',
    admin: 'Administrateur',
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-6 text-[#003366]">Gestion des utilisateurs</h1>

      {/* Bouton pour ajouter un utilisateur */}
      {userRole === 'admin' && (
        <div className="flex justify-end mb-4">
          <button
            onClick={() => {
              setNewMemberEmail('');
              setNewMemberRole('member');
              setNewMemberEnterpriseId('');
              setIsAddingMember(true);
            }}
            className="inline-flex items-center bg-[#003366] hover:bg-[#002244] text-white font-semibold py-2 px-4 rounded-md shadow"
          >
            <PlusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            Ajouter un utilisateur
          </button>
        </div>
      )}

      {/* Liste des membres de l'équipe */}
      <ul role="list" className="divide-y divide-gray-100">
        {teamMembers.map((person) => (
          <li
            key={person.id} // Utiliser une clé unique (par exemple, l'ID de l'utilisateur)
            className="relative flex justify-between items-center gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8"
          >
            <div className="flex min-w-0 gap-x-4">
              <div className="h-12 w-12 flex-none rounded-full bg-gray-50 flex items-center justify-center text-gray-400">
                {getInitials(person.email)}
              </div>
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">{person.email}</p>
                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                  {roleLabels[person.role]} - {enterpriseMap[person.enterpriseID] || 'Mon enseigne'}
                </p>
              </div>
            </div>
            {userRole === 'admin' && (
              <div className="flex shrink-0 items-center gap-x-4">
                <button
                  onClick={() => {
                    setEditingMember(person);
                    setEditingRole(person.role);
                    setEditingEnterpriseId(person.enterpriseID || '');
                    setIsEditingMember(true);
                  }}
                  className="text-sm font-semibold leading-6 text-blue-600 hover:text-blue-800"
                >
                  Modifier
                </button>
              </div>
            )}
          </li>
        ))}
      </ul>

      <Transition.Root show={isAddingMember} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsAddingMember(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      {isLoading ? (
                        <div className="flex justify-center w-full">
                          <svg
                            className="animate-spin h-8 w-8 text-[#003366]"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </div>
                      ) : (
                        <>
                          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-[#003366] sm:mx-0 sm:h-10 sm:w-10">
                            <PlusCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                              Ajouter un utilisateur
                            </Dialog.Title>
                            <div className="mt-2">
                              <form onSubmit={handleAddMember} className="space-y-4">
                                <div>
                                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email de l'utilisateur
                                  </label>
                                  <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={newMemberEmail}
                                    onChange={(e) => setNewMemberEmail(e.target.value)}
                                    required
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-[#003366] focus:border-[#003366]"
                                  />
                                </div>
                                <div>
                                  <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                    Rôle de l'utilisateur
                                  </label>
                                  <select
                                    name="role"
                                    id="role"
                                    value={newMemberRole}
                                    onChange={(e) => setNewMemberRole(e.target.value)}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-[#003366] focus:border-[#003366]"
                                  >
                                    <option value="member">Membre</option>
                                    <option value="admin">Administrateur</option>
                                  </select>
                                </div>
                                {userRole === 'admin' && (
                                  <div>
                                    <label htmlFor="enterprise" className="block text-sm font-medium text-gray-700">
                                      Entreprise
                                    </label>
                                    <select
                                      name="enterprise"
                                      id="enterprise"
                                      value={newMemberEnterpriseId}
                                      onChange={(e) => setNewMemberEnterpriseId(e.target.value)}
                                      required
                                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-[#003366] focus:border-[#003366]"
                                    >
                                      <option value="">Sélectionnez une entreprise</option>
                                      {enterprises.map((enterprise) => (
                                        <option key={enterprise.id} value={enterprise.id}>
                                          {enterprise.name || 'Mon enseigne'}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {!isLoading && (
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        onClick={handleAddMember}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#003366] text-base font-medium text-white hover:bg-[#002244] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3399FF] sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Envoyer l'invitation
                      </button>
                      <button
                        type="button"
                        onClick={() => setIsAddingMember(false)}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3399FF] sm:mt-0 sm:w-auto sm:text-sm"
                      >
                        Annuler
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Modal pour modifier un utilisateur */}
      <Transition.Root show={isEditingMember} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsEditingMember(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-[#003366] sm:mx-0 sm:h-10 sm:w-10">
                        <ChevronRightIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                          Modifier l'utilisateur
                        </Dialog.Title>
                        <div className="mt-2">
                          <form onSubmit={handleEditMember} className="space-y-4">
                            <div>
                              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email de l'utilisateur
                              </label>
                              <input
                                type="email"
                                name="email"
                                id="email"
                                value={editingMember ? editingMember.email : ''}
                                disabled
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm bg-gray-100 focus:ring-[#003366] focus:border-[#003366]"
                              />
                            </div>
                            <div>
                              <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                Rôle de l'utilisateur
                              </label>
                              <select
                                name="role"
                                id="role"
                                value={editingRole}
                                onChange={(e) => setEditingRole(e.target.value)}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-[#003366] focus:border-[#003366]"
                              >
                                <option value="member">Membre</option>
                                <option value="admin">Administrateur</option>
                              </select>
                            </div>
                            {userRole === 'admin' && (
                              <div>
                                <label htmlFor="enterprise" className="block text-sm font-medium text-gray-700">
                                  Entreprise
                                </label>
                                <select
                                  name="enterprise"
                                  id="enterprise"
                                  value={editingEnterpriseId}
                                  onChange={(e) => setEditingEnterpriseId(e.target.value)}
                                  required
                                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-[#003366] focus:border-[#003366]"
                                >
                                  <option value="">Sélectionnez une entreprise</option>
                                  {enterprises.map((enterprise) => (
                                    <option key={enterprise.id} value={enterprise.id}>
                                      {enterprise.name || 'Mon enseigne'}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse sm:justify-between">
                    <div className="sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        onClick={handleEditMember}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#003366] text-base font-medium text-white hover:bg-[#002244] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3399FF] sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        Enregistrer
                      </button>
                      <button
                        type="button"
                        onClick={() => setIsEditingMember(false)}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3399FF] sm:mt-0 sm:w-auto sm:text-sm"
                      >
                        Annuler
                      </button>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        onClick={() => handleDeleteMember(editingMember.id)}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mr-3 sm:w-auto sm:text-sm"
                      >
                        Supprimer l'utilisateur
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Team;
