import React, { useEffect, useRef } from 'react';

// components/ModalReception.js

function formatDate(date) {
  return date ? new Date(date).toLocaleDateString('fr-FR') : 'Non spécifiée';
}

function ModalReception({ isOpen, onClose, reception }) {
  const modalRef = useRef();

  useEffect(() => {
    function handleOutsideClick(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen || !reception) return null;

  const images = reception.image_urls || [];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-2xl p-6 w-full max-w-2xl relative"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 text-2xl"
        >
          &#x2715;
        </button>
        <h3 className="text-2xl font-bold text-gray-800 mb-6">
          Détails de la réception
        </h3>
        {images.length > 0 && (
          <div className="mb-6">
            <p className="text-gray-700 mb-2">Photos ({images.length}) :</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {images.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Réception ${index + 1}`}
                  className="w-full h-48 object-cover rounded-md"
                />
              ))}
            </div>
          </div>
        )}
        <table className="w-full text-left text-gray-700">
          <tbody>
            <tr>
              <th className="py-2 pr-4 font-semibold">Fournisseur :</th>
              <td className="py-2">{reception.supplierName}</td>
            </tr>
            <tr>
              <th className="py-2 pr-4 font-semibold">Date de réception :</th>
              <td className="py-2">{formatDate(reception.receptionDate)}</td>
            </tr>
            <tr>
              <th className="py-2 pr-4 font-semibold">
                Température du produit :
              </th>
              <td className="py-2">
                {reception.productTemperature !== null
                  ? `${reception.productTemperature} °C`
                  : 'Non spécifiée'}
              </td>
            </tr>
            <tr>
              <th className="py-2 pr-4 font-semibold">Conformité :</th>
              <td className="py-2">
                {reception.nonConformity ? '❌ Non conforme' : '✅ Conforme'}
              </td>
            </tr>
            {reception.nonConformity && reception.nonConformityDetails && (
              <tr>
                <th className="py-2 pr-4 font-semibold">
                  Détails de non-conformité :
                </th>
                <td className="py-2">{reception.nonConformityDetails}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ModalReception;
