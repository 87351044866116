import React from 'react';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';

export function Cta() {
  return (
    <div className="p-4 sm:p-6 md:p-10 flex justify-center items-center">
      {/* Conteneur principal du CTA */}
      <div className="relative py-8 sm:py-12 md:py-16 px-6 sm:px-10 md:px-20 bg-[#dbeefa] rounded-2xl flex flex-col justify-center items-center gap-6 sm:gap-8 md:gap-12 w-full max-w-7xl overflow-hidden">
        
        {/* SVG à gauche */}
        <img
          src="/dinde.svg"
          alt="Décoration gauche"
          className="absolute left-0 bottom-0 w-1/3 sm:w-1/4 md:w-1/5 max-w-[300px] object-contain opacity-50 sm:opacity-100"
        />
        
        {/* SVG à droite */}
        <img
          src="/Vector.svg"
          alt="Décoration droite"
          className="absolute right-0 top-0 w-1/3 sm:w-1/4 md:w-1/5 max-w-[300px] object-contain opacity-50 sm:opacity-100"
        />

        {/* Texte du CTA */}
        <div className="flex flex-col justify-start items-center gap-2 sm:gap-3 z-10">
          <h2 className="text-[#212121] text-2xl sm:text-3xl md:text-4xl font-bold font-inter text-center">
            Envie d'essayer ?
          </h2>
          <p className="text-[#666666] text-base sm:text-lg md:text-xl font-normal font-inter text-center max-w-2xl">
            Commencez maintenant et profitez de 15 jours gratuits pour vous faire votre avis !
          </p>
        </div>

        {/* Boutons du CTA */}
        <div className="flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-6 md:gap-10 z-10">
          {/* Bouton "Essayer gratuitement" */}
          <button className="w-full sm:w-auto px-6 py-3 sm:py-3.5 bg-black rounded-xl text-white text-sm sm:text-base font-semibold leading-normal hover:bg-gray-800 transition-colors duration-300">
            Essayer gratuitement
          </button>

          {/* Bouton "Nous contacter" avec icône */}
          <motion.div 
            className="flex justify-center items-center gap-2 sm:gap-3 cursor-pointer rounded-xl px-4 py-2 transition-all duration-300"
            whileHover={{ 
              scale: 1.05, 
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              boxShadow: "0 0 0 2px white"
            }}
            whileTap={{ scale: 0.98 }}
          >
            <span className="text-center text-black text-sm sm:text-lg font-medium leading-normal">
              Nous contacter
            </span>
            <motion.div 
              className="p-1 sm:p-1.5 bg-white rounded-full flex justify-center items-center"
              whileHover={{ x: 5 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <ArrowRight className="w-3 h-3 sm:w-3.5 sm:h-3.5 text-black" />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}