import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';



const firebaseConfig = {
    apiKey: "AIzaSyC8QSq6Bpno1Q5c8OyHojHVlEadxqnLAck",
    authDomain: "logiresto.firebaseapp.com",
    projectId: "logiresto",
    storageBucket: "logiresto",
    messagingSenderId: "1090692725256",
    appId: "1:1090692725256:web:f49704b6c142d512e2afbd",
    measurementId: "G-LF2R3X42SZ"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);
  const analytics = getAnalytics(app);
  export const functions = getFunctions(app, 'europe-west1');
  
  export { auth, db, analytics , logEvent };