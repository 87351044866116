// components/DLCOverview.js

import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

function DLCOverview({ selectedEnterpriseId }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (!selectedEnterpriseId) return;

    const dlcCollectionRef = collection(db, 'dlcInfo');
    const q = query(
      dlcCollectionRef,
      where('enterpriseId', '==', selectedEnterpriseId),
      where('archived', '==', false),
      orderBy('expirationDate', 'asc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        expirationDate: doc.data().expirationDate
          ? doc.data().expirationDate.toDate() // Utilisation de toDate() pour convertir Timestamp Firestore
          : null,
      }));
      setProducts(productsData);
    });

    return () => unsubscribe();
  }, [selectedEnterpriseId]);

  function evaluateStatus(product) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const expirationDate = new Date(product.expirationDate);
    expirationDate.setHours(0, 0, 0, 0);

    const msPerDay = 1000 * 60 * 60 * 24;
    const daysUntilExpiration = Math.floor((expirationDate - today) / msPerDay);

    if (product.archived) {
      return 'archived';
    }

    if (daysUntilExpiration < 0) {
      return 'expired';
    } else if (daysUntilExpiration <= 3) {
      return 'warning';
    } else {
      return 'valid';
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="rounded-lg shadow-lg p-6 mb-8 bg-white">
        <h3 className="text-2xl font-bold text-gray-900 mb-6">Suivi des DLC</h3>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {products.map((product) => (
            <div
              key={product.id}
              className="relative p-6 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-200"
            >
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-xl font-semibold text-gray-800">{product.name}</h3>
                {evaluateStatus(product) === 'expired' && (
                  <ExclamationCircleIcon className="w-6 h-6 text-red-500" />
                )}
                {evaluateStatus(product) === 'warning' && (
                  <ExclamationCircleIcon className="w-6 h-6 text-orange-500" />
                )}
                {evaluateStatus(product) === 'valid' && (
                  <CheckCircleIcon className="w-6 h-6 text-green-500" />
                )}
              </div>
              <p className="text-sm text-gray-600">
                Expiration : {product.expirationDate ? product.expirationDate.toLocaleDateString() : 'N/A'}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

DLCOverview.propTypes = {
  selectedEnterpriseId: PropTypes.string.isRequired,
};

export default DLCOverview;
